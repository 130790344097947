<template>
  <b-modal
    :visible="visible"
    title="Insira as cidades que a transportadora irá atender"
    size="xl"
    hide-footer
    @change="$emit('change', false)"
  >
    <b-row>
      <b-col>
        <form-input
          @enter="searchCity"
          v-model="search"
          label="Pesquisar cidade"
          icon="search"
        ></form-input>
      </b-col>
      <b-col md="4">
        <form-select
          v-model="uf"
          @change="findCityByUf"
          label="Estado"
          icon="pin-map"
          :options="ufListOptions"
        ></form-select>
      </b-col>
      <b-col md="3" class="d-flex align-items-end mb-3">
        <b-button @click="addAllUf" variant="primary" size="md">
          <div v-if="!spin">ADICIONAR TODO O ESTADO</div>
          <b-icon v-if="spin" icon="arrow-clockwise" animation="spin"></b-icon>
        </b-button>
      </b-col>
    </b-row>
    <b-table :items="cityList" :fields="ufFields">
      <template #cell(actions)="row">
        <b-button
          :disabled="row.item.loading"
          :variant="row.item.shipping_value.length ? 'danger' : 'success'"
          size="sm"
          @click="addCityToCompany(row.item)"
        >
          <b-icon
            :icon="
              row.item.loading
                ? 'arrow-clockwise'
                : row.item.shipping_value.length
                ? 'x-circle'
                : 'check-circle'
            "
            :animation="row.item.loading ? 'spin' : ''"
          ></b-icon>
        </b-button>
      </template>
    </b-table>
    <b-button v-if="showLoadMoreButton" block @click="searchType"
      >CARREGAR MAIS
      <b-icon v-if="spin" icon="arrow-clockwise" animation="spin"></b-icon>
    </b-button>
  </b-modal>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../form/FormInput.vue";
import FormSelect from "../form/FormSelect.vue";
export default {
  components: { FormSelect, FormInput },
  model: {
    event: "change",
    prop: "visible",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      uf: "",
      page: 2,
      cityId: 0,
      search: "",
      spin: false,
      cityList: [],
      searchOn: false,
      showLoadMoreButton: false,
      ufFields: [
        {
          key: "city",
          label: "Cidade",
          sortable: true,
        },

        {
          key: "uf",
          label: "Estado",
          sortable: true,
          thStyle: { width: "100px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],
      ufListOptions: [
        { id: "", text: "" },
        { id: "AC", text: "ACRE" },
        { id: "AL", text: "ALAGOAS" },
        { id: "AP", text: "AMAPÁ" },
        { id: "AM", text: "AMAZONAS" },
        { id: "BA", text: "BAHIA" },
        { id: "CE", text: "CEARÁ" },
        { id: "DF", text: "DISTRITO FEDERAL" },
        { id: "ES", text: "ESPÍRITO SANTO" },
        { id: "GO", text: "GOIÁS" },
        { id: "MA", text: "MARANHÃO" },
        { id: "MT", text: "MATO GROSSO" },
        { id: "MS", text: "MATO GROSSO DO SUL" },
        { id: "MG", text: "MINAS GERAIS" },
        { id: "PA", text: "PARÁ" },
        { id: "PB", text: "PARAÍBA" },
        { id: "PR", text: "PARANÁ" },
        { id: "PE", text: "PERNAMBUCO" },
        { id: "PI", text: "PIAUÍ" },
        { id: "RJ", text: "RIO DE JANEIRO" },
        { id: "RN", text: "RIO GRANDE DO NORTE" },
        { id: "RS", text: "RIO GRANDE DO SUL" },
        { id: "RO", text: "RONDÔNIA" },
        { id: "RR", text: "RORAIMA" },
        { id: "SC", text: "SANTA CATARINA" },
        { id: "SP", text: "SÃO PAULO" },
        { id: "SE", text: "SERGIPE" },
        { id: "TO", text: "TOCANTINS" },
      ],
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),
    async searchType() {
      if (this.searchOn) {
        await this.searchMoreCity();
      } else {
        await this.loadMoreCity();
      }
    },
    async findCityByUf() {
      this.searchOn = false;
      let response;
      this.page = 2;
      if (this.search) {
        response = await http(
          `shipping/searchCity?uf=${this.uf}&search=${this.search}&companyId=${this.companyId}&page=1`
        );
      } else {
        response = await http(
          `shipping/cityList?companyId=${this.companyId}&uf=${this.uf}&page=1`
        );
      }

      if (response.data.length === 20) {
        this.showLoadMoreButton = true;
      }
      this.cityList = response.data;
    },
    async loadMoreCity() {
      this.spin = true;
      const { data } = await http(
        `shipping/cityList?companyId=${this.companyId}&uf=${this.uf}&page=${this.page}`
      );

      if (data.length === 20) {
        this.showLoadMoreButton = true;
      } else {
        this.showLoadMoreButton = false;
      }
      this.page += 1;
      this.cityList.push(...data);
      this.spin = false;
    },
    async searchCity() {
      this.searchOn = true;
      this.page = 1;
      this.spin = true;
      const { data } = await http(
        `shipping/searchCity?search=${this.search}&companyId=${this.companyId}&uf=${this.uf}&page=1`
      );

      if (data.length === 20) {
        this.showLoadMoreButton = true;
      } else {
        this.showLoadMoreButton = false;
      }
      this.page += 1;
      this.cityList = data;
      this.spin = false;
    },
    async searchMoreCity() {
      this.spin = true;
      const { data } = await http(
        `shipping/searchCity?search=${this.search}&companyId=${this.companyId}&uf=${this.uf}&page=${this.page}`
      );

      if (data.length === 20) {
        this.showLoadMoreButton = true;
      } else {
        this.showLoadMoreButton = false;
      }
      this.page += 1;
      this.cityList.push(...data);
      this.spin = false;
    },
    async addAllUf() {
      try {
        this.spin = true;
        await http.post(
          `shipping/addAllCity?companyId=${this.companyId}&uf=${this.uf}`
        );

        const { data } = await http(
          `shipping/cityList?companyId=${this.companyId}&uf=${this.uf}&page=1`
        );
        this.cityList = data;
        this.setToastedSuccess({
          message: "Estado adicionado com sucesso",
        });
      } catch (error) {
        this.spin = false;
        this.setToastedError({
          message: error.response.data.error,
        });
      }
      this.spin = false;
    },
    async addCityToCompany(item) {
      this.cityId = item.id;
      this.uf = item.uf;

      const index = this.cityList.findIndex(
        (element) => element.id === this.cityId
      );
      this.cityList.splice(index, 1, { ...item, loading: true });

      const { data } = await http.patch(
        `shipping/city/${this.companyId}`,
        item
      );

      this.cityList.splice(index, 1, data);
    },
  },
};
</script>
