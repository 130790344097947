<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Transportadoras</h4>
        </div>

        <div class="d-flex justify-content-end mb-2">
          <b-button variant="primary" @click="openRegisterCompanyModal"
            >REGISTRAR NOVA TRASPORTADORA</b-button
          >
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="companyListFormated"
            :fields="companyFields"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                class="mr-1"
                :variant="row.item.display ? 'danger' : 'success'"
                size="sm"
                @click="openShowModal(row.item)"
              >
                <b-icon icon="eye"> </b-icon>
              </b-button>

              <b-button
                class="mr-1"
                variant="primary"
                size="sm"
                @click="showAddCityModal(row.item)"
              >
                <b-icon icon="plus-lg"></b-icon>
              </b-button>

              <b-button
                class="mr-1"
                variant="primary"
                size="sm"
                @click="showCityOfCompanyModal(row.item)"
              >
                <b-icon icon="card-checklist"></b-icon>
              </b-button>

              <b-button
                class="mr-1"
                variant="primary"
                size="sm"
                @click="changeRouteToDetail(row.item)"
              >
                <b-icon icon="pencil"> </b-icon>
              </b-button>

              <b-button
                size="sm"
                variant="danger"
                @click="showDeactivateCompanyModal(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-card-text>

        <register-company-modal
          v-model="registerCompanyModal"
          :companyInfo="companyInfo"
          :key="`${registerCompanyModal}-registerCompany`"
          @newCompany="(data) => companyList.push(data)"
        >
        </register-company-modal>

        <b-modal
          v-model="deactivateCompanyModal"
          title="Desativar transportadora"
          hide-footer
        >
          <p>
            Deseja desativar permanentemente a transportadora "{{
              companyName
            }}"
          </p>
          <div class="d-flex justify-content-end mb-2">
            <b-button @click="deactivateCompany">DESATIVAR</b-button>
          </div>
        </b-modal>

        <b-modal v-model="showModal" title="Exibir transportadora" hide-footer>
          <p v-if="!show">Deseja exibir a transportadora "{{ companyName }}"</p>
          <p v-else>
            Deseja deixar de exibir a transportadora "{{ companyName }}"
          </p>
          <div class="d-flex justify-content-end mb-2">
            <b-button
              :variant="!show ? 'success' : 'danger'"
              @click="showCompany"
              >OK</b-button
            >
          </div>
        </b-modal>

        <add-city-to-company
          v-model="addCityModal"
          :companyId="companyId"
          :key="`${addCityModal}-addCityToCompany`"
        ></add-city-to-company>

        <list-city-of-company
          v-model="cityOfCompanyModal"
          :companyId="companyId"
          :key="`${cityOfCompanyModal}-listCityOfcompany`"
        ></list-city-of-company>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import RegisterCompanyModal from "../../components/shippingCompany/RegisterCompanyModal.vue";
import AddCityToCompany from "../../components/shippingCompany/AddCityToCompany.vue";
import ListCityOfCompany from "../../components/shippingCompany/ListCityOfCompany.vue";
export default {
  components: {
    RegisterCompanyModal,
    AddCityToCompany,
    ListCityOfCompany,
  },
  data() {
    return {
      page: 2,
      pageSearch: 1,
      registerCompanyModal: false,
      deactivateCompanyModal: false,
      companyId: 0,
      showModal: false,
      show: false,
      addCityModal: false,
      cityList: [],
      companyName: "",
      spin: false,
      showLoadMoreButton: false,
      uf: "",
      search: "",
      searchOn: false,
      cityId: null,
      cityOfCompanyModal: false,
      companyList: [],
      shippingValue: null,

      companyInfo: {
        oficialName: "",
        socialName: "",
        cnpj: "",
        ie: "",
        zipCode: "",
        address: "",
        number: "",
        complement: "",
        references: "",
        district: "",
        city: "",
        state: "",
        email: "",
        billMethod: "",
        deadline: "",
        minimumValue: "",
      },
      companyFields: [
        {
          key: "socialName",
          label: "Nome Social",
          sortable: true,
        },
        {
          key: "cnpj",
          label: "CNPJ",
          sortable: true,
          thStyle: { width: "166px !important" },
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          thStyle: { width: "100px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "220px !important" },
        },
      ],
    };
  },

  computed: {
    companyListFormated() {
      return this.companyList.map((company) => {
        return {
          ...company,
          cnpj: formatCpfCnpj(company.cnpj),
        };
      });
    },
  },

  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    openRegisterCompanyModal() {
      this.companyInfo = {
        oficialName: "",
        socialName: "",
        cnpj: "",
        ie: "",
        zipCode: "",
        address: "",
        number: "",
        complement: "",
        references: "",
        district: "",
        city: "",
        state: "",
        email: "",
        deadline: "",
        minimumValue: "",
      };
      this.registerCompanyModal = true;
    },

    showDeactivateCompanyModal(item) {
      this.deactivateCompanyModal = true;
      this.companyName = item.socialName;
      this.companyId = item.id;
    },

    changeRouteToDetail(item) {
      this.$router.push(`transportadoras/${item.id}/detalhes`);
    },

    openShowModal(item) {
      this.companyId = item.id;
      this.companyName = item.socialName;
      this.show = item.display;
      this.showModal = true;
    },

    // BLOCO EXIBICAO DE CIDADES !!INICIO!!

    async showCityOfCompanyModal(item) {
      this.page = 2;
      this.companyId = item.id;
      this.shippingValue = null;
      this.uf = "";
      this.cityOfCompanyModal = true;
    },

    // !!FIM!!

    // BLOCO ADICAO DE CIDADES !!INICIO!!
    showAddCityModal(item) {
      this.companyId = item.id;
      this.addCityModal = true;
      this.showLoadMoreButton = false;
      this.uf = "";
      this.cityList = [];
      this.search = "";
    },

    async deactivateCompany() {
      this.deactivateCompanyModal = false;
      this.setOverlay(true);
      await http.put(`/shipping/deactivate/${this.companyId}`);

      const company = this.companyList.filter(
        (company) => company.id !== this.companyId
      );

      this.companyList = company;

      this.setOverlay(false);
      this.setToastedSuccess({
        message: "Transportadora desativada com sucesso",
      });
    },

    async showCompany() {
      this.showModal = false;
      this.setOverlay(true);
      const { data } = await http.put(`shipping/show/${this.companyId}`, {
        show: !this.show,
      });
      const index = this.companyList.findIndex(
        (element) => element.id === data.id
      );
      this.companyList.splice(index, 1, data);
      this.setOverlay(false);
      this.setToastedSuccess({
        message: data.display
          ? `A transportadora "${data.socialName}" está em exibição`
          : `A transportadora "${data.socialName}" não está mais em exibição`,
      });
    },

    // !!FIM!!
  },
  async created() {
    this.setOverlay(true);
    const { data } = await http("/shipping");
    this.companyList.push(...data);
    this.setOverlay(false);
  },
};
</script>
