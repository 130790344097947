<template>
  <b-modal
    :visible="visible"
    title="Cidades atendidas por essa transportadora"
    size="xl"
    hide-footer
    @change="$emit('change', false)"
  >
    <b-form @submit.prevent="updateManyShippingValue">
      <b-row>
        <b-col md="6">
          <form-select
            v-model="uf"
            label="Estado"
            required
            icon="pin-map"
            :options="ufListOptions"
            @change="getCityOfCompanyByUf"
          ></form-select>
        </b-col>
        <b-col md="2">
          <form-number
            v-model="shippingValue"
            label="Valor Único"
            icon="cash-coin"
            required
          ></form-number>
        </b-col>
        <b-col class="d-flex align-items-center">
          <b-button block class="mt-3" variant="primary" type="submit"
            >APLICAR VALOR A TODO O ESTADO
            <b-icon v-if="spin" icon="arrow-clockwise" animation="spin"></b-icon
          ></b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-table :items="cityList" :fields="cityInfoField">
      //
      <template #cell(shippingValue)="row">
        <form-number
          v-model="row.item.shippingValue"
          @enter="updateShippingValue(row.item)"
        ></form-number>
      </template>
      <template #cell(actions)="row">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="updateShippingValue(row.item)"
        >
          <b-icon
            :icon="row.item.isLoading ? 'hourglass-split' : 'sd-card'"
            :animation="row.item.isLoading ? 'spin' : ''"
          ></b-icon>
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="deleteShippingValue(row.item)"
        >
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
    <b-button v-if="showLoadMoreButton" block @click="getMoreCityOfCompanyByUf"
      >CARREGAR MAIS
      <b-icon v-if="spin" icon="arrow-clockwise" animation="spin"></b-icon
    ></b-button>
  </b-modal>
</template>
<script>
import http from "../../plugins/http";
import FormNumber from "../form/FormNumber.vue";
import FormSelect from "../form/FormSelect.vue";
export default {
  components: { FormSelect, FormNumber },
  model: {
    event: "change",
    prop: "visible",
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    visible: {
      immediate: true,
      async handler(value) {
        if (value) {
          const { data } = await http(
            `shipping/cityOfCompany/${this.companyId}?page=1`
          );
          this.cityList = data.map((city) => {
            return {
              ...city,
              shippingValue: city.shippingValue.toString().replace(".", ","),
            };
          });
          if (data.length === 20) {
            this.showLoadMoreButton = true;
          } else {
            this.showLoadMoreButton = false;
          }
        }
      },
    },
  },
  data() {
    return {
      spin: false,
      page: 2,
      uf: "",
      cityId: 0,
      cityList: [],
      shippingValue: 0,
      showLoadMoreButton: false,
      ufListOptions: [
        { id: "", text: "" },
        { id: "AC", text: "ACRE" },
        { id: "AL", text: "ALAGOAS" },
        { id: "AP", text: "AMAPÁ" },
        { id: "AM", text: "AMAZONAS" },
        { id: "BA", text: "BAHIA" },
        { id: "CE", text: "CEARÁ" },
        { id: "DF", text: "DISTRITO FEDERAL" },
        { id: "ES", text: "ESPÍRITO SANTO" },
        { id: "GO", text: "GOIÁS" },
        { id: "MA", text: "MARANHÃO" },
        { id: "MT", text: "MATO GROSSO" },
        { id: "MS", text: "MATO GROSSO DO SUL" },
        { id: "MG", text: "MINAS GERAIS" },
        { id: "PA", text: "PARÁ" },
        { id: "PB", text: "PARAÍBA" },
        { id: "PR", text: "PARANÁ" },
        { id: "PE", text: "PERNAMBUCO" },
        { id: "PI", text: "PIAUÍ" },
        { id: "RJ", text: "RIO DE JANEIRO" },
        { id: "RN", text: "RIO GRANDE DO NORTE" },
        { id: "RS", text: "RIO GRANDE DO SUL" },
        { id: "RO", text: "RONDÔNIA" },
        { id: "RR", text: "RORAIMA" },
        { id: "SC", text: "SANTA CATARINA" },
        { id: "SP", text: "SÃO PAULO" },
        { id: "SE", text: "SERGIPE" },
        { id: "TO", text: "TOCANTINS" },
      ],
      cityInfoField: [
        {
          key: "city.city",
          label: "Cidade",
        },
        {
          key: "city.uf",
          label: "Estado",
          thStyle: { width: "100px !important" },
        },
        {
          key: "shippingValue",
          label: "Valor (R$)",
          thStyle: { width: "150px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],
    };
  },
  methods: {
    async updateShippingValue(item) {
      this.cityId = item.id;

      const index = this.cityList.findIndex(
        (element) => element.id === this.cityId
      );
      this.cityList.splice(index, 1, { ...item, isLoading: true });

      await http.put(`shipping/value/${item.id}`, {
        shippingValue: item.shippingValue,
      });
      this.cityList.splice(index, 1, {
        city: item.city,
        cityId: item.cityId,
        id: item.id,
        shippingCompanyId: item.shippingCompanyId,
        shippingValue: item.shippingValue,
      });
    },
    async deleteShippingValue(item) {
      await http.delete(`shipping/${item.id}`);

      const newList = this.cityList.filter((oldList) => oldList.id !== item.id);
      this.cityList = newList;
    },
    async getCityOfCompanyByUf() {
      this.page = 2;
      const { data } = await http(
        `shipping/cityOfCompany/${this.companyId}?uf=${this.uf}&page=1`
      );
      if (data.length === 20) {
        this.showLoadMoreButton = true;
      } else {
        this.showLoadMoreButton = false;
      }

      this.cityList = data.map((city) => {
        return {
          ...city,
          shippingValue: city.shippingValue.toString().replace(".", ","),
        };
      });
    },
    async updateManyShippingValue() {
      this.spin = true;
      await http.put(`shipping/manyValue/${this.companyId}`, {
        shippingValue: this.shippingValue,
        uf: this.uf,
      });
      await this.getCityOfCompanyByUf();
      this.spin = false;
    },
    async getMoreCityOfCompanyByUf() {
      this.spin = true;
      const { data } = await http(
        `shipping/cityOfCompany/${this.companyId}?uf=${this.uf}&page=${this.page}`
      );
      if (data.length === 20) {
        this.showLoadMoreButton = true;
      } else {
        this.showLoadMoreButton = false;
      }

      const cities = data.map((value) => {
        return {
          ...value,
          shippingValue: value.shippingValue.toString().replace(".", ","),
        };
      });
      this.cityList.push(...cities);
      this.page += 1;
      this.spin = false;
    },
  },
};
</script>
