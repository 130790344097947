<template>
  <b-modal
    :visible="visible"
    size="xl"
    title="Insira as informações de cadastro"
    hide-footer
    @change="$emit('change', false)"
  >
    <b-form @submit.prevent="registerCompany">
      <b-row>
        <b-col md="3">
          <form-input
            v-model="companyInfoCopy.cnpj"
            :errors="errors.cnpj"
            label="CNPJ/CPF"
            icon="building"
            required
          ></form-input>
        </b-col>

        <b-col md="3">
          <form-input
            v-model="companyInfoCopy.ie"
            :errors="errors.ie"
            label="Inscrição estadual"
            icon="file-medical"
            required
          ></form-input>
        </b-col>

        <b-col md="6">
          <form-input
            v-model="companyInfoCopy.email"
            :errors="errors.email"
            label="Email"
            icon="at"
            required
          ></form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <form-input
            v-model="companyInfoCopy.oficialName"
            :errors="errors.oficialName"
            label="Nome oficial"
            icon="card-heading"
            required
          ></form-input>
        </b-col>

        <b-col md="6">
          <form-input
            v-model="companyInfoCopy.socialName"
            :errors="errors.socialName"
            label="Nome social"
            icon="people"
            required
          ></form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="2">
          <form-input
            v-model="companyInfoCopy.zipCode"
            :errors="errors.zipCode"
            label="CEP"
            icon="signpost-split"
            required
            @change="getInfoByCep"
            @enter="getInfoByCep"
          ></form-input>
        </b-col>

        <b-col md="8">
          <form-input
            v-model="companyInfoCopy.address"
            :errors="errors.address"
            label="Endereço"
            icon="signpost-split"
            required
          ></form-input>
        </b-col>

        <b-col md="2">
          <form-input
            v-model="companyInfoCopy.number"
            :errors="errors.number"
            label="Número"
            icon="mailbox"
            required
          ></form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <form-input
            v-model="companyInfoCopy.district"
            :errors="errors.district"
            label="Bairro"
            icon="map"
            required
          ></form-input>
        </b-col>

        <b-col>
          <form-input
            v-model="companyInfoCopy.city"
            :errors="errors.city"
            label="Cidade"
            icon="pin-map"
            required
          ></form-input>
        </b-col>

        <b-col>
          <form-input
            v-model="companyInfoCopy.state"
            :errors="errors.state"
            label="Estado"
            icon="signpost-split"
            required
          ></form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <form-input
            v-model="companyInfoCopy.references"
            :errors="errors.references"
            label="Referência"
            icon="signpost-split"
            required
          ></form-input>
        </b-col>

        <b-col>
          <form-input
            v-model="companyInfoCopy.complement"
            :errors="errors.complement"
            label="Complemento"
            icon="card-text"
            required
          ></form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="3">
          <form-select
            v-model="companyInfoCopy.billMethod"
            :errors="errors.billMethod"
            label="Método de cobrança"
            icon="cash-coin"
            :options="billMethodsOptions"
            required
          ></form-select>
        </b-col>

        <b-col>
          <form-number
            v-model="companyInfoCopy.deadline"
            :errors="errors.deadline"
            label="Dias uteis para entrega"
            icon="stopwatch"
            required
          ></form-number>
        </b-col>
        <b-col>
          <form-number
            v-model="companyInfoCopy.minimumValue"
            :errors="errors.minimumValue"
            icon="coin"
            label="Valor minimo"
            required
          >
          </form-number>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-end mb-2">
        <b-button variant="primary" type="submit"> SALVAR </b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../form/FormInput.vue";
import FormNumber from "../form/FormNumber.vue";
import FormSelect from "../form/FormSelect.vue";
export default {
  components: { FormInput, FormNumber, FormSelect },
  model: {
    event: "change",
    prop: "visible",
  },
  props: {
    companyInfo: {
      type: Object,
      default: () => ({
        oficialName: "",
        socialName: "",
        cnpj: "",
        ie: "",
        zipCode: "",
        address: "",
        number: "",
        complement: "",
        references: "",
        district: "",
        city: "",
        state: "",
        email: "",
        billMethod: "",
        deadline: "",
        minimumValue: "",
      }),
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      companyInfoCopy: {
        ...this.companyInfo,
      },
      billMethodsOptions: [
        { id: "PORCENTAGEM", text: "Porcentagem" },
        { id: "VALOR", text: "Valor" },
      ],
      errors: {},
    };
  },
  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    async registerCompany() {
      try {
        const { data } = await http.post(`/shipping`, {
          ...this.companyInfoCopy,
          deadline: Number(this.companyInfoCopy.deadline),
          minimumValue: Number(this.companyInfoCopy.minimumValue),
        });
        this.$emit("newCompany", data);
        this.setToastedSuccess({
          message: "Transportadora registrada com sucesso",
        });
        this.registerCompanyModal = false;
        this.$emit("change", false);
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
    },

    async getInfoByCep(cep) {
      const { data } = await axios(
        `https://brasilapi.com.br/api/cep/v1/${cep}`
      );
      this.companyInfoCopy = {
        ...this.newAddress,
        address: data.street,
        district: data.neighborhood,
        zipCode: data.cep,
        state: data.state,
        city: data.city,
      };
    },
  },
};
</script>
